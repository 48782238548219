<template>
  <div class="chengename">
    <!-- 头部 -->
    <van-nav-bar
      title="分销员申请"
      left-arrow
      @click-left="onClickLeft"
      class="dark_bg"
    />
    <div class="input-text">
      <!-- <h2 class="autonym-header">分销员申请</h2> -->
      <van-field
          v-model="name"
          label="姓名:"
          placeholder="请输入姓名"
          :disabled="disabled"
          class="dark_shop_bg input-right"
          input-align="right"
        >
        </van-field>
        <van-field
          v-model="mobile"
          label="手机号:"
          placeholder="请输入手机号"
          :disabled="disabled"
          class="dark_shop_bg input-right"
          input-align="right"
        />
        <van-field
          v-model="cardNumber"
          label="身份证号:"
          placeholder="请输入身份证号码"
          :disabled="disabled"
          class="dark_shop_bg input-right"
          input-align="right"
        />
    </div>
    <!-- 新版本 -->
    <div class="gap"></div>

    <section class="load-idimage" v-if="deviceEnvir != 'PC' && edition != 'low' && !isWeix">
      <p
        class="self_upstyle dark_shop_bg"
        @click="showPhoto('5686', '身份证正面')"
      >
        <img :src="foreSrc" alt v-if="foreSrc" />
        <label v-else>
          <img src="@/assets/images/idimg.png" alt="" class="id-img" />
          <i class="btn-red">上传正面</i>
        </label>
      </p>
      <p
        class="self_upstyle dark_shop_bg"
        @click="showPhoto('5687', '身份证反面')"
      >
        <img :src="backSrc" alt v-if="backSrc" />
        <label v-else>
          <img src="@/assets/images/idimg2.png" alt="" class="id-img" />
          <i class="btn-red">上传反面</i>
        </label>
      </p>
    </section>

    <section class="load-idimage" v-if="edition != 'low' && isWeix">
      <van-uploader
        :after-read="afterRead1"
        :max-count="1"
        v-model="zheng"
        :before-read="beforeRead"
        :deletable="deletable"
        :disabled="!kill"
      >
        <p class="self_upstyle dark_shop_bg">
          <img :src="foreSrc" alt v-if="foreSrc" />

          <label v-else>
            <img src="@/assets/images/idimg.png" alt="" class="id-img" />
            <i class="btn-red">上传正面</i>
          </label>
        </p>
      </van-uploader>
      <van-uploader
        :after-read="afterRead2"
        :max-count="1"
        v-model="fan"
        :before-read="beforeRead"
        :deletable="deletable"
        :disabled="!kill"
      >
        <p class="self_upstyle dark_shop_bg">
          <img :src="backSrc" alt v-if="backSrc" />

          <label v-else>
            <img src="@/assets/images/idimg2.png" alt="" class="id-img" />
            <i class="btn-red">上传反面</i>
          </label>
        </p>
      </van-uploader>
    </section>
    <section class="load-idimage" v-if="deviceEnvir == 'PC'">
      <van-uploader
        :after-read="afterRead1"
        :max-count="1"
        v-model="zheng"
        :before-read="beforeRead"
        :deletable="deletable"
        :disabled="!kill"
      >
        <p class="self_upstyle dark_shop_bg">
          <img :src="foreSrc" alt v-if="foreSrc" class="imgPC"/>

          <label v-else>
            <img src="@/assets/images/idimg.png" alt="" class="id-img" />
            <i class="btn-red">上传正面</i>
          </label>
        </p>
      </van-uploader>
      <van-uploader
        :after-read="afterRead2"
        :max-count="1"
        v-model="fan"
        :before-read="beforeRead"
        :deletable="deletable"
        :disabled="!kill"
      >
        <p class="self_upstyle dark_shop_bg">
          <img :src="backSrc" alt v-if="backSrc" />

          <label v-else>
            <img src="@/assets/images/idimg2.png" alt="" class="id-img" />
            <i class="btn-red">上传反面</i>
          </label>
        </p>
      </van-uploader>
    </section>
    <div class="gap"></div>
    <div class="require">
      <div class="require-title">拍摄身份证要求：</div>
      <p>
        大陆公民持有的本人有效二代身份证；<br />
        拍摄时确保身份证<span class="red">边框完整，字体清晰，亮度均匀；</span>
      </p>
      <div class="photo-require">
        <div class="logo-item">
          <img
            src="@/assets/images/realName/icon_img1.png"
            alt=""
            class="logo"
          />
          <!-- <img src="@/assets/images/realName/right.png" alt="" class="icon" /> -->
          <div class="txt">标准</div>
        </div>
        <div class="logo-item">
          <img
            src="@/assets/images/realName/icon_img2.png"
            alt=""
            class="logo"
          />
          <!-- <img src="@/assets/images/realName/err.png" alt="" class="icon" /> -->
          <div class="txt">边框缺失</div>
        </div>
        <div class="logo-item">
          <img
            src="@/assets/images/realName/icon_img3.png"
            alt=""
            class="logo"
          />
          <!-- <img src="@/assets/images/realName/err.png" alt="" class="icon" /> -->
          <div class="txt">对焦模糊</div>
        </div>
        <div class="logo-item">
          <img
            src="@/assets/images/realName/icon_img4.png"
            alt=""
            class="logo"
          />
          <!-- <img src="@/assets/images/realName/err.png" alt="" class="icon" /> -->
          <div class="txt">闪光强烈</div>
        </div>
      </div>
    </div>

    <p  class="bottom-btn">
      <!-- <span v-if="kill" class="isChecked-text">未认证，请您等待管理员认证...</span> -->
      <!-- <Btn @click.native="sureEdit">保存提交</Btn> -->
      
      <van-button
        v-if="parentId"
        class="vant-btn"
        @click.native="inviteSureEdit"
        size="large"
        :disabled="canGet"
        >提交
      </van-button>
      <van-button
        v-else
        class="vant-btn"
        @click.native="sureEdit"
        size="large"
        :disabled="canGet"
        >提交
      </van-button>
    </p>
    <!-- <p class="checked">{{ isCheckedFm }}</p> -->
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
  </div>
</template>

<script>
import { NavBar, Field, Uploader, Toast, ActionSheet } from "vant";
import axios from "axios";
import Btn from "@/views/common/btn";

export default {
  data() {
    return {
      name: null,
      mobile: null,
      cardNumber: null,
      isWeix: false,

      isAndroid: false,
      foreSrc: null,
      backSrc: null,
      customerNum: localStorage.getItem("customerId"),
      disabled: false,
      zheng: [],
      fan: [],
      accessToken: localStorage.getItem("token"),
      paramsObj: null,
      isChecked: "0",
      isCheckedFm: "",
      deletable: true,
      show: false,
      sign: "",
      name: undefined,
      obj: "",
      canGet: false,
      kill: true,
      img1: "",
      img2: "",
      actions: [
        { name: "请选择", disabled: true },
        { name: "拍照", color: "#3291f8" },
        { name: "从相册获取", color: "#3291f8" },
        { name: "取消", color: "#3291f8" },
      ],
      edition: localStorage.getItem("edition"),
      deviceEnvir: this.Base.deviceEnvir(),
      parentId: this.$route.query.parentId
    };
  },
  components: {
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [ActionSheet.name]: ActionSheet,
    [Uploader.name]: Uploader,
    Btn,
  },
  created() {
    this.androidIOS();
    // this.getCustomerNum();
    window.imageCallback = this.imageCallback;
    this.is_weixn();
    console.log('deviceEnvir', this.deviceEnvir);

    // let url = location.href
    // console.log(url, url.indexOf('?storeId='));
    // if(url.indexOf('?storeId=') != -1){
    //   let storeId = url.split('?storeId=')[1]
    //   localStorage.setItem('storeId', storeId)
    // }
  },
  mounted() {
    // 暴露给IOS使用
    window.invokeJS = this.invokeJS;
  },
  methods: {
    // 弹出框
    onSelect(action, index) {
      // console.log(action,index);
      let paramsObj = {
        // accessToken: localStorage.getItem("token"),
        sign: this.sign,
        // bizId: this.customerNum,
        // securityLevel: "4",
        // name: this.name,
      };
      console.log(paramsObj);
      switch (index) {
        // 拍照
        case 1:
          if (this.isAndroid) {
            paramsObj = JSON.stringify(paramsObj);
            window.revisionInterface.takePhoto(paramsObj);
          }
          if (!this.isAndroid) {
            window.webkit.messageHandlers.takePhoto.postMessage(paramsObj);
          }
          this.show = false;
          break;
        // 打开相册
        case 2:
          if (this.isAndroid) {
            paramsObj = JSON.stringify(paramsObj);
            window.revisionInterface.selectImage(paramsObj);
          }
          if (!this.isAndroid) {
            window.webkit.messageHandlers.selectImage.postMessage(paramsObj);
          }
          this.show = false;
          break;
        case 3:
          this.show = false;
          break;
      }
    },

    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.isWeix = true;
        // this.handleWx();
      } else {
        this.isWeix = false;
      }
    },
    // 暴露给安卓调用
    imageCallback(params) {
      if (!this.isAndroid) {
        this.obj = JSON.parse(params);
        // console.log(this.obj);
        let { sign, imageUrl } = this.obj;
        if (sign == "5686") {
          this.foreSrc = imageUrl;
        } else {
          this.backSrc = imageUrl;
        }
      } else {
        // console.log(params);
        let { sign, imageUrl } = params;
        if (sign == "5686") {
          this.foreSrc = imageUrl;
        } else {
          this.backSrc = imageUrl;
        }
      }
    },
    afterRead(file) {
      console.log(file);
      this.$api.my.adminByBase64(file.content).then((res) => {
        console.log(res);
      }).catch;
      this.img1 = file.content;
    },
    afterReadBack(file) {
      this.img2 = file.content;
    },
    // 显示弹出框
    showPhoto(sign, name) {
      if (!this.kill) {
        return;
      }
      // console.log(sign,name,"sign");
      if (this.isChecked == "1") {
        return;
      }
      this.show = true;
      this.sign = sign;
      // this.name = name;
    },
    // 判断是安卓还是IOS环境
    androidIOS() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // 判断是否是 android终端
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 判断是否是 ios终端
      if (isAndroid === true) {
        // 安卓
        this.isAndroid = true;
      } else if (isIOS === true) {
        this.isAndroid = false;
        // IOS
      } else {
      }
    },
    // 获取客户编号
    getCustomerNum() {
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中",
      });
      this.$api.my
        .getCertificateInfo({
          accessToken: this.accessToken,
          // customerId: localStorage.getItem("customerId"),
        })
        .then((res) => {
          toast.clear();
          console.log(res);
          // if (res.errno == 200) {
          if (res.data) {
            let username_name = res.data.name;
            let idNum_number = res.data.number;
            let foreSrc_frontImageUrl = res.data.frontImageUrl;
            let backSrc_backImageUrl = res.data.backImageUrl;
            this.username = username_name;
            this.idNum = idNum_number;
            this.foreSrc = foreSrc_frontImageUrl;
            this.backSrc = backSrc_backImageUrl;
            if (res.data.status === 0) {
              this.kill = true;
            } else {
              this.kill = false;
            }
          }

          // if (res.returnValue.isChecked == "1") {
          //   this.deletable = false;
          //   this.disabled = true;
          // }
          // if (res.returnValue.frontImageUrl) {
          //   this.foreSrc = res.returnValue.frontImageUrl;
          //   this.zheng.push({ url: res.returnValue.frontImageUrl });
          // }
          // if (res.returnValue.backImageUrl) {
          //   this.backSrc = res.returnValue.backImageUrl;
          //   this.fan.push({ url: res.returnValue.backImageUrl });
          // }
          // }
        });
    },
    // 调用相册
    getPictures(sign) {
      // console.log("ios");
      // if (this.status == 1) {
      //     return;
      // }
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // 判断是否是 android终端
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 判断是否是 ios终端
      if (isAndroid === true) {
        // 安卓
        return "Android";
      } else if (isIOS === true) {
        // 调用IOS约定好的调用相册方法
        const paramsObj = {
          cmd: "005",
          accessToken: this.accessToken,
          sign,
          bizId: this.customerNum,
        };
        window.webkit.messageHandlers.invokeiOS.postMessage(paramsObj);
        return "IOS";
      } else {
        return "PC";
      }
    },
    onClickLeft() {
      this.$router.back();
    },
    // 返回布尔值
    beforeRead(file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg"
      ) {
        return true;
      } else {
        this.$toast("不支持上传该格式的图片");
        return false;
      }
    },
    // 上传图片
    afterRead1(file) {
      let formData = new FormData();
      // formData.append("accessToken", localStorage.getItem("token"));
      // formData.append("bizId", this.customerNum);
      // formData.append("sign", "5686");
      // formData.append("mode", 0);
      formData.append("file", file.file);
      // formData.append("securityLevel", "4");
      this.$api.my.fileUploadAddress(formData).then((res) => {
        // console.log(res);
        this.img1 = res.url;
      });
    },
    afterRead2(file) {
      let formData = new FormData();
      // formData.append("accessToken", localStorage.getItem("token"));
      // formData.append("bizId", this.customerNum);
      // formData.append("sign", "5687");
      // formData.append("mode", 0);
      formData.append("file", file.file);
      // formData.append("securityLevel", "4");
      this.$api.my.fileUploadAddress(formData).then((res) => {
        // console.log(res);
        this.img2 = res.url;
      });
    },
    // 确定修改
    sureEdit() {
      this.canGet = true;
      
      if (!this.name) {
        this.$toast("请输入姓名");
        setTimeout(() => {
          this.canGet = false;
        }, 2000);
        return false;
      }
      if (!this.mobile) {
        this.$toast("请输入手机号");
        setTimeout(() => {
          this.canGet = false;
        }, 2000);
        return false;
      }
      let storeId = localStorage.getItem("storeId")
      
      console.log('storeId', storeId);
      this.$api.distributor
          .applyJoinDistribution({
            // accessToken: localStorage.getItem("token"),
            storeId: storeId != 'null' && storeId ? storeId : 0,
            userId: localStorage.getItem("customerId"),
            name: this.name,
            mobile: this.mobile,
            cardNumber: this.cardNumber,
            cardUpImg: this.img1 || this.foreSrc,
            cardDownImg: this.img2 || this.backSrc,
          })
          .then((res) => {
            console.log('申请', res);
            if (res.errno == 200) {
              this.$toast({
                message: "申请成功",
                duration: 1000,
                onClose: () => {
                  this.$router.replace({ path: "/my" });
                },
              });
            } else {
              this.$toast(res.errmsg);
              // console.log(res);
              // this.canGet=false;
            }
          });
      setTimeout(() => {
        this.canGet = false;
      }, 2000);
    },
    // 邀请
    inviteSureEdit() {
      console.log('邀请');
      
      this.canGet = true;
      
      if (!this.name) {
        this.$toast("请输入姓名");
        setTimeout(() => {
          this.canGet = false;
        }, 2000);
        return false;
      }
      if (!this.mobile) {
        this.$toast("请输入手机号");
        setTimeout(() => {
          this.canGet = false;
        }, 2000);
        return false;
      }
      let storeId = this.$route.query.storeId
      console.log('storeId',storeId, storeId == 'null');
      
      this.$api.distributor
          .inviteJoinDistribution({
            // accessToken: localStorage.getItem("token"),
            storeId: storeId != 'null' && storeId ? storeId : 0,
            userId: localStorage.getItem("customerId"),
            parentId: this.parentId,
            name: this.name,
            mobile: this.mobile,
            cardNumber: this.cardNumber,
            cardUpImg: this.img1 || this.foreSrc,
            cardDownImg: this.img2 || this.backSrc,
          })
          .then((res) => {
            console.log('申请', res);
            if (res.errno == 200) {
              this.$toast({
                message: "申请成功",
                duration: 1000,
                onClose: () => {
                  this.$router.replace({ path: "/my" });
                },
              });
            } else {
              this.$toast(res.errmsg);
              // console.log(res);
              // this.canGet=false;
            }
          });
      setTimeout(() => {
        this.canGet = false;
      }, 2000);
    },
    // 暴露给IOS调用的方法
    invokeJS(params) {
      // this.$toast(params);
      let paramsObj = JSON.parse(params);
      let { cmd, returnValue } = paramsObj;
      this.paramsObj = paramsObj;
      if (cmd == "005") {
        // 正面
        if (returnValue.returnValue.sign == "5686") {
          this.foreSrc = returnValue.returnValue.outLink;
        } else {
          this.backSrc = returnValue.returnValue.outLink;
        }
      }
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../less/element.less";

// @red: #c83f3f;
.checked {
  color: #c83f3f;
  position: absolute;
  top: 1rem;
  right: 0.48rem;
  z-index: 2002;
}

.chengename {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 0.64rem;
  position: relative;
  background: #fff;

  .van-popup--bottom {
    left: 2.5%;
    width: 95%;
  }

  /deep/ .van-overlay {
    background-color: rgba(0, 0, 0, 0.35) !important;
  }

  .van-nav-bar {
    // margin: 0 0 0.32rem;

    .van-icon {
      color: #ccc;
    }

    .van-nav-bar__title {
      color: #333;
      letter-spacing: 0.04rem /* 2/50 */;
    }
  }

  .van-cell {
    padding: 0.32rem;

    // .getsms {
    //   color: @red;
    // }
  }

  .load-idimage {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    // flex-direction: column;
    justify-content: space-around;
    align-items: center;
    // margin: .2rem 0 0rem;
    padding: 0.38rem 0.14rem 0.4rem;
    background-color: #fff;

    & /deep/ .van-uploader__preview-image {
      // width: 16/3.125rem;
      // height: 9/3.125rem;
      width: 3.24rem;
      height: 2.78rem;
    }
    
    .self_upstyle {
      background-color: #f4f8fe;

      width: 3.24rem;
      // height: 2.8rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.8/3.125rem;
      // border: .03rem /* 1.5/50 */ dashed #ccc;
      margin: 0 0 0.32rem;
      color: rgb(126, 125, 125);
      border-radius: 0.1rem;
      overflow: hidden;

      label {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .btn-red {
          display: block;
          width: 100%;
          background: @upload_idcard_label;
          border-radius: 0px 0px 0.1rem 0.1rem;
          text-align: center;

          font-size: 0.28rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.6rem;
          font-style: normal;
        }

        span {
          font-size: 2/3.125rem;
          margin: 0 0 0.32rem;
        }
      }

      img {
        width: 2.43rem;
        height: 1.62rem;
      }
    }

    .van-uploader__preview-image {
      width: 8rem;
      height: 2.88rem;
    }
  }

  & > p {
    text-align: center;
    // margin: 0.64rem 0 0;

    .btn {
      .btnmin(
                    @padding:0.7/3.125rem 6/3.125rem,
                    @radius:0.2/3.125rem,
                    @size:1/3.125rem,
                    @bgcolor:@ordinary_btn_color,
                    @color:#fff
                );
    }
  }
}

.id-img {
  margin-top: 0.28rem;
  margin-bottom: 0.28rem /* 10/50 */;
  // border: .03rem /* 1.5/50 */ dashed #ccc;
}
.bottom-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.3rem;
  background: #ffffff;
  box-shadow: 0px 0px 0.2rem 0px rgba(7, 63, 53, 0.1);
  .vant-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6.3rem;
    height: 0.9rem;
    // background: linear-gradient(0deg, #ee3a3a, #f46161);
    background: @ordinary_btn_color;
    border-radius: 0.1rem;
    font-size: 0.36rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.36rem;
  }
}

.input-text {
  position: relative;
  .svg-icon {
    position: absolute;
    right: 0.3rem /* 15/50 */;
    top: 0.78rem;
    width: 1.2rem /* 60/50 */;
    z-index: 2;
  }
}
.isChecked-text {
  display: block;
  color: #c83f3f;
  font-size: 0.24rem /* 12/50 */;
}

.require {
  box-sizing: border-box;
  width: 100%;
  padding: 0.38rem /* 20/16 */ 0.3rem /* 15/16 */;
  background-color: #fff;
  .require-title {
    font-size: 0.3rem /* 15/16 */;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333;
    margin-bottom: 0.3rem /* 15/16 */;
  }
  p {
    font-size: 0.26rem /* 13/16 */;
    font-family: PingFang SC;
    font-weight: 400;
    color: #919191;
    line-height: 0.32rem /* 16/16 */;
    .red {
      color: #e73146;
    }
  }
  .photo-require {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.28rem /* 15/16 */;
  }
  .logo-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .logo {
      // width: 5rem /* 80/16 */;
      // width: 90%;
      width: 1.6rem;
    }
    .icon {
      // width: .78rem /* 22/16 */;
      width: 1.6rem;
      position: absolute;
      // top: 2.19rem /* 35/16 */;
    }
    .txt {
      margin-top: 0.26rem /* 10/16 */;
      font-size: 0.26rem /* 13/16 */;
      font-family: PingFang SC;
      font-weight: 400;
      color: #919191;
    }
  }
}

.gap {
  width: 100%;
  height: 0.2rem /* 10/16 */;
  background: #f6f6f6;
}
.autonym-header {
  padding: 0.3rem 0.32rem 0.08rem;
  font-size: 0.36rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  background: #fff;
}
/deep/ .van-field__label {
  color: #7e7e7e;
}
.input-right {
  text-align: right !important;
  ::-webkit-input-placeholder {
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #aeaeae;
    line-height: 0.36rem;
  }
}
</style>